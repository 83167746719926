/* App.css */
/* Root CSS */
:root {
  /* --main-red-color: rgb(227, 55, 55); */
  --main-red-color: rgb(210, 31, 60) !important;
  --main-blue-color: #0477bf !important;
  --main-cardBackground-color: #D8E9FD !important
}

.home-root {
  background-color: rgb(250, 249, 246);
  min-height: 100%;
  margin-top: auto;
  /* padding-bottom: 3;
  padding-top: 3; */
  margin-left: auto;
  min-width: 275;
}

.outlet-root {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
}

/* ---------------------------------------------------------------------------------------------------- */
/* Background color */
.home-colorBackground {
  background-color: rgb(186, 209, 234);
}

/* ---------------------------------------------------------------------------------------------------- */
/* image CSS */
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.inline-content img {
  margin-right: 10px; /* Space between image and text */
}

.member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #ccc;
  /* by changing margin 35px (to < 38px) below you control how many team members cards will fitt in one row */
  margin: 5px;
}

.aboutimg {
  width: 100%;
  height: auto;
  border: 1px solid #f3f0f0;
  margin: 10px 0;
}

/* ---------------------------------------------------------------------------------------------------- */
/* Content Style */
.outlet-main-content {
  text-align: center;
  flex: 1 1 auto;
  overflow: auto;
}

.outlet-content {
  flex: 1 1 auto;
  overflow: auto;
}

.inline-content {
  display: flex;
  align-items: center; /* Align items vertically center */
}

.object-contents {
  flex:auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: fit-content;
}

.about-text {
  font-family:"lato", sans-serif !important;
  font-size: x-large !important;
  margin: left 80px !important;
  /* color: red; */
}

.home-intro-title {
  font-size: 28px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.Account-Servers-title {
  font-size: 37px;
}

.bold-title {
  font-weight: bold;
}

.resources-citation {
  text-align: left;
}

/* ---------------------------------------------------------------------------------------------------- */
/* Page box components */
.About-half-width-box{
  min-width: 350px;/* min-width: 275px; Ensure both boxes have a minimum width */
  width: 90%; /* Half the width of the container minus spacing */
  margin: 0 auto; /* Add spacing between the boxes */
  border: 1px solid #ccc; /* Add a border for visual separation */
  border-radius: 8px; /* Add border radius for rounded corners */
  display: flex; /* Use flexbox */
  justify-content: center !important;
  align-items: center; /* Center content horizontally */
  text-align: left;
  padding: 16px;
}

.About-Full-Width-Box{
  width: 100%;
  min-width: 275px; 
  border: 1px #ccc; 
  border-radius: 5%; 
  display: flex;
  justify-content: flex-start; 
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
  margin-bottom: 50px;
  margin-top: 20px;
} 

.outlet-container {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: 75px;
}

.home-linkcard {
  min-height: 350px;
  min-width: 275;
  text-align: center;
  margin-top: 5;
}

.Account-Servers-server-card {
  min-width: 275px;
  min-height: 250px;
  max-width: 500px;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 12px;
  margin-left: 12px;
}

.large_textfield {
  border-style: inherit;
  width: max-content;
  grid-auto-rows: auto;
}

.required-field {
  color: var(--main-red-color) !important;
}

.custom-container {
  margin: 5%;
  padding-top: 0; /* Ensure the container starts at the top of the page */
}

.custom-table-container {
  overflow-x: auto; /* Makes the table scrollable horizontally */
  display: block;  /* Allows the table container to respect the width constraints */
}

.object-sidebar {
  width: 250px;
  flex-shrink: 0;
  overflow: none;
  height: 600px;
}

/* ---------------------------------------------------------------------------------------------------- */
/* Page margin layout */
.about-main {
  margin-top: 3%;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 2%;
}

.home-margintop{
  margin-top: 50px;
}

.object-domain {
  margin-bottom: 35px;
}

/* Paddings */
.table-card {
  padding: 2%;
}

/* ---------------------------------------------------------------------------------------------------- */
/* Navigation Bar CSS */
.nav-link {
  input {color: white};
  text-decoration: none;
  color: white;
  background-color: primary;
}

.hover-background {
  color: inherit;
  transition: background-color 0.3s ease;
}

.grow {
  flex-grow: 1;
}

.image-container {
  max-width: 100%;
  height: auto;
  img {
    width: "2em";
  }
} /* Specificlly for Nav Bar imag */

.section-desktop {
  display: none;
  @media (min-width: 600px) {
    display: flex;
  }
}
.section-mobile {
  display: flex;
  @media (min-width: 600px) {
    display: none;
  }
}

/* ---------------------------------------------------------------------------------------------------- */
/* Icon CSS */
.white-icon {
  color: white;
}

.loading-icon {
  display: inline-block;
  animation: spin 1s linear infinite;
  width: 50px;
  height: 50px;
  background: transparent;
}

.hover-background:hover {
  background-color: inherit; /* Change to the desired background color on hover */
}

/* ---------------------------------------------------------------------------------- */
/* Button Styles */
.add-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: rgb(37, 92, 181) !important;
} 

.delete-button {
  color: var(--main-red-color) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-confirm {
  color: blue;
}

.object-buttons {
  display: flex;
  justify-content: center;
  align-items: center; 
}

/* ---------------------------------------------------------------------------------- */
/* PopUps Styles */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for better visibility */
  position: relative;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content button {
  margin: 10px;
}
